import { createTheme } from "@material-ui/core/styles"
import colors from "./colors"

const defaultTheme = createTheme()
const { breakpoints } = defaultTheme

export default createTheme({
    shadows: [
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none"
  ],
  typography: {
    fontFamily: "Lato",
    h1: {
      fontFamily: "PT Serif",
      color: colors.primary,
      fontSize: "40px",
      textTransform: "none",
      [breakpoints.up("sm")]: {
        fontSize: "64px"
      }
    },
    h2: {
      fontFamily: "PT Serif",
      color: colors.primary,
      fontSize: "28px",
      textTransform: "uppercase",
      [breakpoints.up("sm")]: {
        fontSize: "36px"
      }
    },
    h3: {
      fontFamily: "PT Serif",
      color: colors.primary,
      fontSize: "1.8rem"
    },
    h4: {
      fontFamily: "PT Serif",
      fontSize: 16,
      textTransform: "uppercase",
      letterSpacing: "2px",
      fontWeight: 700
    },
    h5: {
      color: "rgb(121, 121, 121)",
      textTransform: "uppercase",
      fontSize: 12,
      letterSpacing: "2.4px"
    },
    h6: {},
    subtitle1: {
      fontWeight: 700,
      letterSpacing: "0.125rem",
      fontSize: 14,
      lineHeight: 1,
      textTransform: "uppercase"
    },
    subtitle2: {},
    body1: {
      textTransform: "none",
      fontWeight: 400,
      fontSize: 18,
      lineHeight: 1.6,
      letterSpacing: 0,
      color: "rgb(121, 121, 121)"
    },
    body2: {
      textTransform: "none",
      fontWeight: 400,
      fontSize: 18,
      lineHeight: 1.6,
      letterSpacing: 0,
      color: "rgb(121, 121, 121)"
    },
    caption: {
      textTransform: "uppercase",
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: "1px",
      color: "#151414"
    },
    button: {}
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: "white"
    },
    secondary: {
      main: colors.secondary
    }
  },
  // https://material-ui.com/customization/themes/
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: "0.81rem"
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        padding: "0.9375rem 1.875rem",
        fontWeight: 700,
        letterSpacing: "0.125rem"
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none"
        }
      },
      outlinedSecondary: {
        padding: "10px 30px",
        color: "#fff",
        border: "3px solid #fff",
        "&:hover": {
          border: `3px solid ${colors.primary}`,
          backgroundColor: colors.primary
        }
      }
    }
  }
})
