import React from "react"
import styled from 'styled-components'
import Slider from "react-slick"
import ArrowBack from "@material-ui/icons/ArrowBackIos"
import ArrowForward from "@material-ui/icons/ArrowForwardIos"
import { IconButton } from "@material-ui/core"

// import app components
import { BackgroundImage } from "components"
import * as theme from "theme"
import { useStore } from "store"

export default function GallerySlider(props) {
  const { images, backgroundSize = "cover", noSlider, ...rest } = props

  const [, dispatch] = useStore()

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <StyledIconButton>
        <ArrowForward />
      </StyledIconButton>
    ),
    prevArrow: (
      <StyledIconButton>
        <ArrowBack />
      </StyledIconButton>
    )
  }

  const showLightBox = index => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        open: true,
        slide: index,
        slides: images.map(o => {
          return { image: o }
        }),
        options: {
          thumbs: true
        }
      }
    })
  }

  return (
    <Container {...rest}>
      {noSlider || images.length === 1 ? (
        <Slide onClick={() => showLightBox(0)}>
          <BackgroundImage backgroundSize={backgroundSize} image={images[0]} />
        </Slide>
      ) : (
        <Slider {...settings}>
          {images &&
            images.map((image, index) => {
              return (
                <div key={index}>
                  <Slide onClick={() => showLightBox(index)}>
                    <BackgroundImage
                      backgroundSize={backgroundSize}
                      image={image}
                    />
                  </Slide>
                </div>
              )
            })}
        </Slider>
      )}
    </Container>
  )
}

const Container = styled.div`
  height: 100%;

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide div {
    height: 100%;
  }

  .slick-slider {
    position: relative;
    width: 100%;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: "";
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir="rtl"] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  /* Arrows */
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translate(0, -50%);
    display: block;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: #fff;
    border-radius: 0;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
  }

  .slick-prev {
    left: 0;

    @media (min-width: 640px) {
      left: -25px;
    }
  }

  .slick-next {
    right: 0;

    @media (min-width: 640px) {
      right: -25px;
    }
  }
`

const Slide = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const StyledIconButton = styled(IconButton)`
  svg {
    path {
      fill: ${theme.colors.primary};
    }
  }
`
