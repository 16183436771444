export default function formatPrice(string) {
  const number = string.replace(" CAD", "")

  const amount = new Intl.NumberFormat("en-EN", {
    style: "currency",
    currency: "CAD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(number)

  return amount
}
