import React from "react"
import styled from 'styled-components'

// import app components
import { Header, Footer, LightBox } from "components"

export default props => {
  return (
    <Container>
      <Header {...props} />
      <Main>{props?.children}</Main>
      <Footer {...props} />

      <LightBox />
    </Container>
  )
}

const Container = styled.div`
  background: #f3faf9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`

const Main = styled.main``
