import React, { useState } from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

// import app components
import { Button, Edges, Spacer } from "components"
import GallerySlider from "components/gallerySlider"
import * as theme from "theme"
import formatPrice from "utils/formatPrice"

const Boats = (props) => {
  const { option, boatType, button } = props

  const { nodes: boats } = useBoatsQuery()

  const [type, setType] = useState(`all`)
  const [length, setLength] = useState(`all`)

  const availableBoatTypes = []
  const availableLengths = []

  boats.map((o) => {
    o?.boatTypes?.nodes &&
      o.boatTypes.nodes.map((p) => {
        if (!availableBoatTypes.includes(p.name)) {
          availableBoatTypes.push(p.name)
        }
      })

    // Get all available lengths
    // If boat type is selected, then filter by it as well

    o?.lengths?.nodes &&
      o.lengths.nodes.map((p) => {
        const boatTypes = o?.boatTypes?.nodes || []

        if (!boatType) {
          if (
            type !== `all` &&
            boatTypes.find((q) => q.name === type) &&
            !availableLengths.includes(p.name)
          ) {
            availableLengths.push(p.name)
          }
        } else {
          if (
            !availableLengths.includes(p.name) &&
            o?.boatTypes?.nodes &&
            o.boatTypes.nodes.find(
              (q) => q.databaseId === boatType[0].databaseId
            )
          ) {
            availableLengths.push(p.name)
          }
        }
      })
  })

  const visibleBoats = boats.filter((o) => {
    if (!boatType) {
      if (type !== `all`) {
        if (
          o?.boatTypes?.nodes &&
          !o.boatTypes.nodes.find((p) => p.name === type)
        ) {
          return null
        }
      }
    } else {
      if (
        o?.boatTypes?.nodes &&
        !o.boatTypes.nodes.find((p) => p.databaseId === boatType[0].databaseId)
      ) {
        return null
      }
    }

    if (length !== `all`) {
      if (
        o?.lengths?.nodes &&
        !o.lengths.nodes.find((p) => p.name === length)
      ) {
        return null
      }
    }

    return o
  })

  const availableBoats = visibleBoats.filter(
    (o) =>
      o?.availabilities?.nodes &&
      o.availabilities.nodes.find(
        (p) => p.databaseId === 12 || p.databaseId === 25
      )
  )
  const soldBoats = visibleBoats.filter(
    (o) =>
      o?.availabilities?.nodes &&
      o.availabilities.nodes.find((p) => p.databaseId === 13)
  )

  const handleChangeType = (s) => {
    setType(s)
    // Reset length on boat type change
    setLength(`all`)
  }

  const renderBoat = (o) => {
    return (
      <Boat key={o.databaseId}>
        <BoatGallery>
          {o?.boat?.images && <GallerySlider noSlider images={o?.boat?.images} />}
          {o.availabilities.nodes.find((p) => p.databaseId === 26) && (
            <Status variant="caption" children={"Pending"} />
          )}
        </BoatGallery>

        <BoatContent>
          <BoatHeadline variant="h3" children={o.title} />

          {o.boat.length && (
            <BoatAttribute>
              <BoatAttributeLabel variant="subtitle1" children={`Length`} />
              <BoatAttributeValue children={o.boat.length} />
            </BoatAttribute>
          )}

          {o.boat.location && (
            <BoatAttribute>
              <BoatAttributeLabel variant="subtitle1" children={`Location`} />
              <BoatAttributeValue children={o.boat.location} />
            </BoatAttribute>
          )}

          {o.boat.price && (
            <BoatAttribute>
              <BoatAttributeLabel variant="subtitle1" children={`Price`} />
              <BoatAttributeValue
                children={
                  o.boat.price.includes("CAD")
                    ? formatPrice(o.boat.price)
                    : o.boat.price
                }
              />
            </BoatAttribute>
          )}

          {o.boat.year && (
            <BoatAttribute>
              <BoatAttributeLabel variant="subtitle1" children={`Year`} />
              <BoatAttributeValue children={o.boat.year} />
            </BoatAttribute>
          )}

          <Spacer mt={20}>
            <StyledButton
              to={o.uri}
              children={"View Details"}
              variant="contained"
              color="primary"
            />
          </Spacer>
        </BoatContent>
      </Boat>
    )
  }

  const renderSoldBoat = (o) => {
    return (
      <SoldBoat key={o.databaseId}>
        <SoldBoatGallery>
          {o?.boat?.images && <GallerySlider noSlider images={o?.boat?.images} />}
          <Status variant="caption" children={"Sold"} />
        </SoldBoatGallery>

        <SoldBoatContent>
          <BoatHeadline variant="h3" children={o.title} />

          {o.boat.length && (
            <BoatAttribute>
              <BoatAttributeLabel variant="subtitle1" children={`Length`} />
              <BoatAttributeValue children={o.boat.length} />
            </BoatAttribute>
          )}

          {o.boat.year && (
            <BoatAttribute>
              <BoatAttributeLabel variant="subtitle1" children={`Year`} />
              <BoatAttributeValue children={o.boat.year} />
            </BoatAttribute>
          )}
        </SoldBoatContent>
      </SoldBoat>
    )
  }

  return (
    <Container>
      <Spacer pt={50} pb={50}>
        <Edges size="md">
          {option === `all` && (
            <BoatTypeFilter>
              <div>
                <FilterLabel variant="h5" children={`Boat Type`} />
                <StyledButton
                  onClick={() => handleChangeType(`all`)}
                  children={`all`}
                  variant={type === `all` ? `contained` : `text`}
                />

                {availableBoatTypes.map((s) => (
                  <Spacer key={s} ml={{ md: 5 }}>
                    <StyledButton
                      onClick={() => handleChangeType(s)}
                      children={s}
                      variant={type === s ? `contained` : `text`}
                    />
                  </Spacer>
                ))}
              </div>

              {button && (
                <AllVesselsButton
                  to={button.url}
                  children={button.title || "View All Available Vessels"}
                  target={button.target}
                  variant="contained"
                  color="primary"
                />
              )}
            </BoatTypeFilter>
          )}

          {availableLengths.length > 0 && (
            <BoatTypeFilter>
              <div>
                <FilterLabel variant="h5" children={`Boat Length`} />
                <StyledButton
                  onClick={() => setLength(`all`)}
                  children={`all`}
                  variant={length === `all` ? `contained` : `text`}
                />

                {availableLengths.map((s) => (
                  <Spacer key={s} ml={5}>
                    <StyledButton
                      onClick={() => setLength(s)}
                      children={s}
                      variant={length === s ? `contained` : `text`}
                    />
                  </Spacer>
                ))}
              </div>

              {boatType && button && (
                <AllVesselsButton
                  to={button.url}
                  children={button.title || "View All Available Vessels"}
                  target={button.target}
                  variant="contained"
                  color="primary"
                />
              )}
            </BoatTypeFilter>
          )}

          {availableBoats && availableBoats.map((o) => renderBoat(o))}

          {soldBoats && soldBoats.length > 0 && (
            <Spacer mt={40}>
              <Spacer mb={30}>
                <Typography variant="h2" children={`Sold Boats`} />
              </Spacer>
              <Grid container justify="space-between">
                {soldBoats && soldBoats.map((o) => renderSoldBoat(o))}

                <SoldBoat style={{ height: 0, margin: 0, border: "none" }} />
                <SoldBoat style={{ height: 0, margin: 0, border: "none" }} />
              </Grid>
            </Spacer>
          )}
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  background: #fff;
`

const BoatTypeFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.lg}px) {
    justify-content: space-between;
    margin-bottom: 40px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    flex: 1;

    @media screen and (min-width: ${theme.mui.breakpoints.values.lg}px) {
      margin-bottom: 0;
      justify-content: flex-start;
    }
  }
`

const FilterLabel = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 14px;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
    background: ${theme.colors.primary};
    padding: 10px 20px;

    @media screen and (min-width: ${theme.mui.breakpoints.values.lg}px) {
      width: 125px;
      padding: 0;
      margin-right: 20px;
      margin-bottom: 0;
      color: ${theme.colors.primary};
      background: transparent;
      text-align: left;
    }
  }
`

const StyledButton = styled(Button)`
  && {
    padding: 10px 15px;
    font-size: 14px;
  }
`

const AllVesselsButton = styled(Button)`
  && {
    padding: 10px 15px;
    font-size: 14px;
    display: none;

    @media screen and (min-width: ${theme.mui.breakpoints.values.lg}px) {
      display: flex;
    }
  }
`

const Boat = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: #f7f7f7;
  border: 1px solid #fafafa;
  margin-bottom: 30px;
`

const BoatGallery = styled.div`
  position: relative;
  width: 100%;
  height: 340px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    width: 40%;
  }
`

const BoatContent = styled.div`
  flex: 1;
  padding: 20px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    padding: 20px 30px;
  }
`

const BoatHeadline = styled(Typography)`
  && {
    font-size: 28px;
    margin-bottom: 12px;
  }
`

const BoatAttribute = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 6px;
  padding-top: 6px;
  border-top: 1px solid #e5efef;
`

const BoatAttributeLabel = styled(Typography)`
  && {
    padding-right: 30px;
  }
`

const BoatAttributeValue = styled(Typography)`
  && {
    text-align: right;
  }
`

const SoldBoat = styled.div`
  width: 100%;
  background: #f7f7f7;
  border: 1px solid #fafafa;
  margin-bottom: 30px;

  @media (min-width: 640px) {
    width: calc(50% - 10px);
  }

  @media (min-width: 960px) {
    width: calc(100% / 3 - 10px);
  }
`

const SoldBoatGallery = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
`

const SoldBoatContent = styled.div`
  width: 100%;
  padding: 20px;
`

const Status = styled(Typography)`
  && {
    position: absolute;
    left: 0;
    top: 10px;
    z-index: 1;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 6px;
    color: #fff;
    font-size: 16px;
    background: ${({ children }) =>
      children === "Pending" ? theme.colors.primary : theme.colors.error};
  }
`

const useBoatsQuery = () => {
  const { allWpBoat } = useStaticQuery(
    graphql`
      query {
        allWpBoat(sort: { order: DESC, fields: boat___length }) {
          nodes {
            title
            databaseId
            uri
            boat {
              year
              text
              price
              location
              length
              images {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: AUTO
                      layout: FULL_WIDTH
                      quality: 90
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            boatTypes {
              nodes {
                name
                databaseId
              }
            }
            availabilities {
              nodes {
                name
                databaseId
              }
            }
            lengths {
              nodes {
                name
                databaseId
              }
            }
          }
        }
      }
    `
  )
  return allWpBoat
}

export default Boats
