import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

// import app components
import {
  Edges,
  Form as FormContainer,
  Spacer,
  Textarea,
  Button
} from "components"
import * as theme from "theme"

const Form = (props) => {
  const { address, directions } = props

  const {
    themeOptions: {
      siteOptions: { email, phone, phoneTollFree }
    }
  } = useOptionsQuery()

  return (
    <Spacer pt={50} pb={50}>
      <Edges size="md">
        <Spacer mb={30}>
          <Typography variant="h2" children={`Contact Us`} />
        </Spacer>
        <Grid container>
          <Grid item xs={12} md={5}>
            <Spacer mb={{ xs: 40, md: 0 }} pr={{ md: 40 }}>
              <Typography component="div">
                {phone && <Link href={`tel:${phone}`}>Main: {phone}</Link>}
                {phoneTollFree && (
                  <Link href={`tel:${phoneTollFree}`}>
                    Toll Free: {phoneTollFree}
                  </Link>
                )}
                {email && <Link href={`mailto:${email}`}>Email us</Link>}
              </Typography>

              {address && (
                <Spacer mb={30}>
                  <Textarea content={address} />
                </Spacer>
              )}
              {directions && (
                <Button
                  to={directions.url}
                  children={directions.title}
                  target={directions.target}
                  variant="contained"
                  color="primary"
                />
              )}
            </Spacer>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormContainer formId={1} hideTitle />
          </Grid>
        </Grid>
      </Edges>
    </Spacer>
  )
}

const Link = styled.a`
  text-decoration: none;
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid #e5efef;
  color: ${theme.colors.primary};

  &:hover {
    color: #0079a1;
  }
`

const useOptionsQuery = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          themeOptions {
            siteOptions {
              variant
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: AUTO
                      layout: FULL_WIDTH
                      quality: 90
                      placeholder: BLURRED
                    )
                  }
                }
              }
              headline
              text
              button {
                title
                url
                target
              }
              facebook
              instagram
              email
              phone
              phoneTollFree
            }
          }
        }
      }
    `
  )
  return wp
}

export default Form
