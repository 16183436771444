import React from "react"

// import flexible content modules
import Banner from "./Banner"
import Boats from "./Boats"
import Contact from "./Contact"
import Hero from "./Hero"
import Images from "./Images"
import InstagramFeed from "./InstagramFeed"
import Logos from "./Logos"
import News from "./News"
import Testimonials from "./Testimonials"
import Text from "./Text"
import VideoHero from "./VideoHero"

export default props => {
  const { rows, data } = props

  if (!!rows) {
    return rows
      .filter(o => o !== null)
      .map(({ __typename, ...rowData }, index) => {
          if (!__typename) {
          return null
        }
        const type = __typename.split("_").slice(-1)[0]
        const components = {
          Banner,
          Boats,
          Contact,
          Hero,
          Images,
          InstagramFeed,
          Logos,
          News,
          Testimonials,
          Text,
          VideoHero
        }
        const Component = components[type]
        return (
          Component && (
            <Component
              key={index}
                firstItem={index === 0 ? true : false}
              {...rowData}
              {...data}
            />
          )
        )
      })
  }
}
