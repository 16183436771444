import React from "react"
import styled from 'styled-components'
import { IconButton } from "@material-ui/core"

// import app components
import FacebookIcon from "icons/facebook.svg"
import InstagramIcon from "icons/instagram.svg"
import * as theme from "theme"

const Socials = props => {
  const { facebook, instagram, ...rest } = props

  return (
    <Container {...rest}>
      {facebook && (
        <Link
          href={facebook}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Facebook"
        >
          <StyledIconButton>
            <FacebookIcon />
          </StyledIconButton>
        </Link>
      )}

      {instagram && (
        <Link
          href={instagram}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Instagram"
        >
          <StyledIconButton>
            <InstagramIcon />
          </StyledIconButton>
        </Link>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
`

const Link = styled.a`
  text-decoration: none;
  display: inline-block;
  margin: 0 10px;

  @media (min-width: 1024px) {
    margin: 0 20px 0 0;
  }
`

const StyledIconButton = styled(IconButton)`
  && {
    width: 40px;
    height: 40px;
    background-color: ${theme.colors.primary};
    transition: ease all 0.4s;

    &:hover {
      background-color: #fff;
    }

    svg {
      path {
        fill: ${theme.colors.secondary};
      }
    }
  }
`

export default Socials
