import React, { useEffect, useState } from "react"
import styled from 'styled-components'
import { Typography } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { Heart } from "mdi-material-ui"

// import app components
import { Edges, Spacer, BackgroundImage, Button } from "components"

const InstagramFeed = () => {
  const [instaPosts, setInstaPosts] = useState(null)

  useEffect(() => {
    const fetchInstagramData = async () => {
      const url = `https://www.instagram.com/charlesdavidyachts/?__a=1`
      const response = await fetch(url)

      if (response.ok) {
        const json = await response.json()

        if (
          json &&
          json.graphql &&
          json.graphql.user &&
          json.graphql.user.edge_owner_to_timeline_media
        ) {
          const posts = json.graphql.user.edge_owner_to_timeline_media.edges.map(
            o => {
              return {
                link: `https://www.instagram.com/p/${o.node.shortcode}/`,
                image: {
                  localFile: {
                    childImageSharp: {
                      resize: {
                        aspectRatio:
                          o.node.dimensions.width / o.node.dimensions.height,
                        width: o.node.dimensions.width,
                        height: o.node.dimensions.height
                      },
                      fluid: {
                        aspectRatio:
                          o.node.dimensions.width / o.node.dimensions.height,
                        src: o.node.display_url
                      }
                    }
                  }
                },
                caption: o.node.edge_media_to_caption.edges
                  ? o.node.edge_media_to_caption.edges[0].node.text
                  : "",
                likes: { count: o.node.edge_liked_by.count }
              }
            }
          )

          setInstaPosts(posts)
        }
      }
    }

    fetchInstagramData()
  }, [])

  return (
    <Container>
      <Spacer pt={{ xs: 30, md: 60 }} pb={{ xs: 40, md: 80 }}>
        <Spacer mb={30}>
          <Edges size="md">
            <Grid>
              <Typography variant="h3" children={`Instagram`} />
              <Button
                to={`https://www.instagram.com/charlesdavidyachts/`}
                target="_blank"
                children={`Follow`}
                variant="contained"
                color="primary"
              />
            </Grid>
          </Edges>
        </Spacer>
        <Edges size="md">
          <Grid>
            {!!instaPosts
              ? instaPosts.map(({ link, image, caption, likes }, index) => {
                  return (
                    <InstaPost key={index} href={link} target="_blank">
                      <Overlay className="overlay">
                        {!!likes && likes.count !== 0 && (
                          <span>
                            <Heart />{" "}
                            <Typography color="inherit">
                              {likes.count}
                            </Typography>
                          </span>
                        )}
                      </Overlay>
                      <BackgroundImage
                        alt={caption}
                        image={image}
                        position="absolute"
                      />
                    </InstaPost>
                  )
                })
              : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(key => {
                  return (
                    <InstaPost key={key}>
                      <Skeleton variant="rect" width={`100%`} height={`100%`} />
                    </InstaPost>
                  )
                })}
            <InstaPost style={{ height: 0, padding: 0, margin: 0 }} />
            <InstaPost style={{ height: 0, padding: 0, margin: 0 }} />
            <InstaPost style={{ height: 0, padding: 0, margin: 0 }} />
          </Grid>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  background: ${props => (props.background === "white" ? "#fff" : "#f7f7f7")};
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

const InstaPost = styled.a`
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  height: 180px;
  color: #fff;
  text-decoration: none;

  @media (min-width: 640px) {
    width: calc(100% / 2 - 10px);
    height: 180px;
  }

  @media (min-width: 960px) {
    width: calc(100% / 3 - 10px);
    height: 180px;
  }

  @media (min-width: 1280px) {
    width: calc(100% / 4 - 10px);
    height: 180px;
  }

  &:hover {
    .overlay {
      opacity: 1;
    }
  }
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  span {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    svg {
      margin-right: 8px;
    }
  }
`

export default InstagramFeed
