import React from "react"
import styled from 'styled-components'
import { Typography, Grid } from "@material-ui/core"

// import app components
import { Edges, BackgroundImage, Spacer } from "components"

const Images = props => {
  const { headline, images } = props

  return (
    <Spacer pt={{ xs: 40, md: 60 }} pb={{ xs: 40, md: 60 }}>
      <Edges size="md">
        {headline && (
          <Spacer mb={40}>
            <Headline variant="h2" children={headline} />
          </Spacer>
        )}

        {images && (
          <Grid container justify="space-between">
            {images.map(o => {
              return (
                <ImageContainer>
                  <BackgroundImage image={o.image} />
                  <Content>
                    <Typography
                      variant="h4"
                      color="inherit"
                      children={o.headline}
                    />
                  </Content>
                </ImageContainer>
              )
            })}
          </Grid>
        )}
      </Edges>
    </Spacer>
  )
}

const Headline = styled(Typography)`
  && {
    text-align: center;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 480px;
  margin-bottom: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 30px;
  color: #fff;

  @media (min-width: 640px) {
    width: calc(50% - 15px);
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(196, 196, 196, 0) 0%,
      #000000 100%
    );
    mix-blend-mode: multiply;
    opacity: 0.2;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  color: #fff;
  text-align: center;
`

export default Images
