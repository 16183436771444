import React from "react"
import styled, { css } from "styled-components"
import MenuItems from "./MenuItems"
import { Link } from "gatsby"
import { Typography } from "@material-ui/core"
import Parser from "html-react-parser"
import HamburgerMenu from "react-hamburger-menu"

// import app components
import { Edges } from "components"
import { useStore } from "store"
import * as theme from "theme"
import logo from "images/logo-dark.png"

const MobileMenu = props => {
  const [
    {
      appState: { menu }
    },
    dispatch
  ] = useStore()

  return (
    <>
      <Menu {...props} menuState={menu}>
        <Edges size="sm">
          <Top>
            <LogoContainer>
              <Link
                to="/"
                onClick={() => dispatch({ type: "SET_MENU", payload: false })}
              >
                <img src={logo} alt="Charles David Yachts Logo" />
              </Link>
            </LogoContainer>
            <HamburgerMenuContainer
              onClick={() => dispatch({ type: "TOGGLE_MENU" })}
            >
              <HamburgerMenu
                color={theme.colors.primary}
                isOpen={menu}
                width={30}
                height={15}
                strokeWidth={2}
                menuClicked={() => ""}
              />
            </HamburgerMenuContainer>
          </Top>
        </Edges>
        <Edges size="sm">
          <MenuItems name="mobile-main-menu">
            {items => {
              return items
                .filter(item => item.parentDatabaseId === 0)
                .map(({ id, url, label }) => (
                  <Item
                    onClick={() =>
                      dispatch({ type: "SET_MENU", payload: false })
                    }
                    key={id}
                    to={url}
                    activeClassName="active"
                  >
                    <Title variant="body2">
                      <span>{Parser(label)}</span>
                    </Title>
                  </Item>
                ))
            }}
          </MenuItems>
        </Edges>
      </Menu>

      <Gradient
        onClick={() => dispatch({ type: "SET_MENU", payload: false })}
        menuState={menu}
      />
    </>
  )
}

export default MobileMenu

const Top = styled.div`
  height: 80px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
`

const LogoContainer = styled.div`
  a {
    display: flex;
    text-decoration: none;

    img {
      height: 40px;

      @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
        height: 60px;
      }
    }
  }
`

const HamburgerMenuContainer = styled.div`
  padding: 15px;
  margin-right: -15px;
  cursor: pointer;
`

const menuClose = css`
  transform: translateX(120%);
  opacity: 0;
`
const menuOpen = css`
  transform: translateX(0);
  opacity: 1;
`

const Menu = styled.div`
  ${props => (!!props.menuState ? menuOpen : menuClose)}
  position: fixed;
  height: 100%;
  width: 100%;
  right: 0;
  max-width: 400px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  background: #fff;
  top: 0;
  overflow: auto;
`

const Item = styled(Link)`
  position: relative;
  display: flex;
  padding: 10px 0;
  width: 100%;
  color: ${theme.colors.primary};
  text-decoration: none;

  &.active,
  &:hover {
    color: ${theme.colors.secondary};
  }
`

const Title = styled(Typography)`
  padding: 5px 0;
  color: ${theme.colors.primary};
  text-transform: uppercase;
`

const Gradient = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 90;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${props => (!!props.menuState ? 1 : 0)};
  pointer-events: ${props => (!!props.menuState ? "all" : "none")};
  transition: ease all 0.2s;
`
