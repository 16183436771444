import React from "react"
import styled from 'styled-components'
import { Typography } from "@material-ui/core"
import { Link } from "gatsby"

// import app components
import BackgroundImage from "components/backgroundImage"
import * as theme from "theme"

const NewsPost = props => {
  const { image, date, headline, url, ...rest } = props

  return (
    <Container {...rest}>
      <Link to={url}>
        <ImageContainer>
          {image && <BackgroundImage image={image} />}

          {headline && <Headline variant="h4" children={headline} />}
        </ImageContainer>
      </Link>
    </Container>
  )
}

const Container = styled.div`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.3);
    pointer-events: none;
  }
`

const ImageContainer = styled.div`
  z-index: 1;
  height: 220px;
  width: 100%;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    height: 320px;
  }

  .gatsby-image-wrapper {
    transition: ease all 0.4s;
    transform: scale(1);
  }

  &:hover {
    .gatsby-image-wrapper {
      transform: scale(1.2);
    }
  }
`

const Headline = styled(Typography)`
  && {
    position: absolute;
    z-index: 3;
    bottom: 20px;
    left: 20px;
    width: calc(100% - 40px);
    color: #fff;
  }
`

export default NewsPost
