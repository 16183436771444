import React from "react"
import styled from 'styled-components'

// import app components
import { Edges, Spacer, Textarea, Button } from "components"

const Text = props => {
  const { text, button, backgroundColor } = props

  return (
    <Container background={backgroundColor}>
      <Spacer pt={50} pb={50}>
        <Edges size="md">
          <Textarea content={text} />

          {button && (
            <Spacer mt={30}>
              <ButtonContainer>
                <Button
                  variant="contained"
                  color="primary"
                  to={button.url}
                  children={button.title}
                  target={button.target}
                />
              </ButtonContainer>
            </Spacer>
          )}
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  background: ${props => (props.background === "white" ? "#fff" : "#f7f7f7")};
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export default Text
