import React from "react"
import styled from 'styled-components'
import MenuItems from "./MenuItems"
import { Link } from "gatsby"
import { Typography } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import * as theme from "theme"

const FooterMenu = props => {
  const { name } = props
  return (
    <>
      <Menu {...props}>
        <MenuItems name={name}>
          {items => {
            return items.map(({ id, url, label }) => (
              <Item key={id} to={url} activeClassName="active">
                <Title variant="subtitle1" color="inherit">
                  {Parser(label)}
                </Title>
              </Item>
            ))
          }}
        </MenuItems>
      </Menu>
    </>
  )
}

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
`

const Item = styled(Link)`
  position: relative;
  color: #fff;
  text-decoration: none;
  padding: 8px 20px;
  margin-bottom: 4px;
  width: 100%;

  &.active,
  &:hover {
    color: ${theme.colors.primary};
  }
`

const Title = styled(Typography)`
  && {
    font-size: 12px;
  }
`

export default FooterMenu
