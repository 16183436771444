import React from "react"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

// import app components
import { Edges, Spacer } from "components"
import NewsPost from "components/newsPost"
import * as theme from "theme"

const News = (props) => {
  const { nodes: posts } = usePostQuery()

  return (
    <Spacer pt={{ xs: 40, md: 50 }} pb={{ xs: 40, md: 50 }}>
      <Edges size="lg">
        <Grid container justify="space-between">
          {posts &&
            posts.map((o) => {
              return (
                <StyledNewsPost
                  key={o.id}
                  image={o.news.image}
                  date={o.date}
                  headline={o.title}
                  url={o.uri}
                />
              )
            })}

          <StyledNewsPost
            style={{ height: 0, overflow: "hidden", margin: 0 }}
          />
        </Grid>
      </Edges>
    </Spacer>
  )
}

const StyledNewsPost = styled(NewsPost)`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: calc(50% - 10px);
  }
`

const usePostQuery = () => {
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost {
          nodes {
            id
            title
            uri
            date
            news {
              text
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: AUTO
                      layout: FULL_WIDTH
                      quality: 90
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allWpPost
}

export default News
