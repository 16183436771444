const colors = {
  primary: "#1493a4",
  secondary: "#0b141a",
  background: {
    light: "#fff",
    dark: "#E5EFEF"
  },
  text: {
    light: "#405C5E",
    dark: "#232323"
  },
  success: "#1493a4",
  error: "#e24141"
}

export default colors
