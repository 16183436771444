import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import HamburgerMenu from "react-hamburger-menu"
import { Link, graphql, useStaticQuery } from "gatsby"
import { useScrollYPosition } from "react-use-scroll-position"
import { Typography } from "@material-ui/core"

// import app components
import { DesktopMenu, MobileMenu, Edges } from "components"
import { useStore } from "store"
import { media } from "utils"
import logo from "images/logo.png"
import PhoneIcon from "icons/phone.svg"
import * as theme from "theme"

export default props => {
  const { path } = props

  const [
    {
      appState: { menu }
    },
    dispatch
  ] = useStore()

  const {
    themeOptions: {
      siteOptions: { phone }
    }
  } = useOptionsQuery()

  const scrollY = useScrollYPosition()

  const [pinned, setPinned] = useState(false)

  useEffect(() => {
    dispatch({ type: "SET_MENU", payload: false })
  }, [path, dispatch])

  useEffect(() => {
    setPinned(path !== `/` || !!(scrollY > 120) || menu)
  }, [scrollY, menu])

  return (
    <>
      <HeaderBar pinned={pinned}>
        <Edges size="lg">
          <Nav>
            <LogoContainer>
              <Link
                to="/"
                onClick={() => dispatch({ type: "SET_MENU", payload: false })}
              >
                <img src={logo} alt="Charles David Yachts Logo" />
              </Link>
            </LogoContainer>

            <Right>
              <DesktopMenu
                className="desktop-menu"
                style={{ marginRight: "10px" }}
              />

              {phone && (
                <Phone variant="subtitle1">
                  <a href={`tel:${phone}`}>
                    <PhoneIcon />
                    {phone}
                  </a>
                </Phone>
              )}

              <HamburgerMenuContainer
                onClick={() => dispatch({ type: "TOGGLE_MENU" })}
              >
                <HamburgerMenu
                  color={theme.colors.primary}
                  isOpen={menu}
                  width={30}
                  height={15}
                  strokeWidth={2}
                  menuClicked={() => ""}
                />
              </HamburgerMenuContainer>
            </Right>
          </Nav>
        </Edges>
      </HeaderBar>

      <MobileMenu />
    </>
  )
}

const HeaderBar = styled.div`
  position: fixed;
  width: 100%;
  height: 80px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  z-index: 999;
  transition: ease all 0.3s;
  background: ${props =>
    props.pinned ? theme.colors.secondary : "transparent"};

  @media screen and (min-width: ${theme.mui.breakpoints.values.lg}px) {
    height: 100px;
  }

  &:before {
    content: "";
    position: fixed;
    height: 100px;
    width: 100%;
    z-index: -1;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`

const LogoContainer = styled.div`
  a {
    display: flex;
    text-decoration: none;

    img {
      height: 40px;

      @media screen and (min-width: ${theme.mui.breakpoints.values.lg}px) {
        height: 60px;
      }
    }
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .desktop-menu {
    @media screen and (max-width: ${theme.mui.breakpoints.values.lg - 1}px) {
      display: none;
    }
  }
`

const Phone = styled(Typography)`
  && {
    margin-left: 20px;

    @media screen and (max-width: ${theme.mui.breakpoints.values.lg - 1}px) {
      display: none;
    }

    a {
      display: flex;
      align-items: center;
      color: #fff;

      &:hover {
        color: ${theme.colors.primary};
      }

      svg {
        margin-right: 8px;
        width: 18px !important;
        height: 18px !important;
      }
    }
  }
`

const HamburgerMenuContainer = styled.div`
  padding: 15px;
  margin-right: -15px;
  cursor: pointer;

  @media screen and (min-width: ${theme.mui.breakpoints.values.lg}px) {
    display: none;
  }
`

const useOptionsQuery = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          themeOptions {
            siteOptions {
              phone
            }
          }
        }
      }
    `
  )
  return wp
}
