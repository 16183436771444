import React from "react"
import ReactPlayer from "react-player/lazy"
import { Typography } from "@material-ui/core"
import styled from 'styled-components'

// import app components
import { BackgroundImage } from "components"

const VideoHero = props => {
  const { headline, subline, image, video } = props

  return (
    <Container>
      <Content>
        {(headline || subline) && (
          <Headline variant="h1" children={headline}>
            {headline}
            {subline && <span>{subline}</span>}
          </Headline>
        )}
      </Content>

      {video?.localFile?.publicURL && (
        <VideoContainer>
          <ReactPlayer
            url={video.localFile.publicURL}
            playing={true}
            volume={0}
            muted={true}
            loop={true}
            playsinline={true}
          />

          {image && <BackgroundImage className="poster" image={image} />}
        </VideoContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }
`

const VideoContainer = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  overflow: hidden;

  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-height: 100%;
    z-index: 2;
    object-fit: cover;
  }

  .poster {
    z-index: 1;
    width: 100%;
    height: 100%;

    img {
      object-position: 0% 0% !important;
    }
  }
`

const Content = styled.div`
  position: relative;
  z-index: 4;
  text-align: center;
`

const Headline = styled(Typography)`
  && {
    display: block;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    text-transform: none;

    @media (min-width: 768px) {
      font-size: 40px;
    }

    span {
      display: block;
      font-size: 50px;
      line-height: 1;
      font-weight: 400;
      font-family: "Great Vibes", cursive;

      @media (min-width: 768px) {
        font-size: 120px;
        line-height: 1;
      }
    }
  }
`

export default VideoHero
