import React from "react"
import styled from 'styled-components'
import { Typography } from "@material-ui/core"

// import app components
import { BackgroundImage, Button, Edges, Spacer } from "components"
import bgPattern from "images/bg-repeat.png"

const Banner = props => {
  const { variant, headline, text, image, button } = props

  const renderElement = () => {
    const headlineEl = headline && (
      <Spacer mb={20}>
        <Typography
          variant="h2"
          color={variant === "option1" ? "inherit" : "primary"}
          children={headline}
        />
      </Spacer>
    )

    const textEl = text && (
      <Spacer mb={30}>
        <Typography color="inherit" children={text} />
      </Spacer>
    )

    const buttonEl = button && (
      <Button
        to={button.url}
        children={button.title}
        target={button.target}
        variant="outlined"
        color="secondary"
      />
    )

    if (variant === "option1") {
      return (
        <>
          {image && <BackgroundImage image={image} />}

          <Edges size="md">
            <ContentOption1>
              {headlineEl}
              {textEl}
              {buttonEl}
            </ContentOption1>
          </Edges>
        </>
      )
    } else if (variant === "option2") {
      return (
        <>
          {image && (
            <ImageContainer>
              <BackgroundImage image={image} />
            </ImageContainer>
          )}

          <ContentOption2>
            <div>
              {headlineEl}
              {textEl}
              {buttonEl}
            </div>
          </ContentOption2>
        </>
      )
    }
  }

  return <Container variant={variant}>{renderElement()}</Container>
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;

  &:before {
    content: ${props => props.variant === "option1" && "''"};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.3);
  }

  &:after {
    content: ${props => props.variant === "option2" && "''"};
    position: absolute;
    right: 34%;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(
      to right,
      rgba(11, 20, 26, 0) 0%,
      rgba(11, 20, 26, 1) 100%
    );
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 0;
  height: 100%;

  @media (min-width: 1024px) {
    width: 66%;
  }
`

const ContentOption1 = styled.div`
  position: relative;
  z-index: 2;
  padding: 40px 0;
  width: 100%;
  color: #fff;

  @media (min-width: 1024px) {
    width: 50%;
  }
`

const ContentOption2 = styled.div`
  position: relative;
  z-index: 2;
  padding: 20px 2.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  text-align: center;
  background: #0b141a url(${bgPattern}) repeat scroll 0 0;

  @media (min-width: 1024px) {
    width: 34%;
  }
`

export default Banner
