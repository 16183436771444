import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

// import app components
import { Edges, Socials, Spacer } from "components"
import FooterMenu from "components/menu/FooterMenu"
import Banner from "components/flexibleContent/Banner"
import * as theme from "theme"
import logo from "images/logo.png"

export default (props) => {
  const showBottomBanner = props?.data?.wpPage?.settings?.showBottomBanner

  const {
    themeOptions: {
      siteOptions: {
        variant,
        image,
        headline,
        text,
        button,
        instagram,
        facebook,
        phone,
        phoneTollFree
      }
    }
  } = useOptionsQuery()

  const year = new Date()

  return (
    <>
      {showBottomBanner && (
        <Banner
          variant={variant}
          image={image}
          headline={headline}
          text={text}
          button={button}
        />
      )}
      <Container>
        <Edges size="md">
          <Content>
            <LogoContainer>
              <img src={logo} alt="Charles David Yachts Logo" />
            </LogoContainer>

            <Grid item xs={12} md={6}>
              <Spacer
                mt={{ xs: 50, md: 0 }}
                mb={{ xs: 50, md: 0 }}
                pl={{ md: 30 }}
                pr={{ md: 30 }}
              >
                <Grid container justify="space-between">
                  <FooterMenu name="footer-1" />
                  <FooterMenu name="footer-2" />
                </Grid>
              </Spacer>
            </Grid>

            <SocialsContainer>
              <SocialsHeader
                variant="h3"
                children={`Stay Updated`}
                color="inherit"
              />
              <Socials instagram={instagram} facebook={facebook} />
            </SocialsContainer>
          </Content>

          <Divider />

          {phone && (
            <Phone variant="subtitle1">
              <a href={`tel:${phone}`}>{phone}</a>
            </Phone>
          )}

          {phoneTollFree && (
            <Phone variant="subtitle1">
              <a href={`tel:${phoneTollFree}`}>Toll Free. {phoneTollFree}</a>
            </Phone>
          )}

          <Credits variant="h5">
            © {year.getFullYear()} Charles David Yachts | Premium Yacht Sales.
            All Rights Reserved.
          </Credits>
        </Edges>
      </Container>
    </>
  )
}

const Container = styled.div`
  padding: 60px 0 40px;
  background: ${theme.colors.secondary};

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    padding: 80px 0 60px;
  }
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    justify-content: space-between;
  }
`

const LogoContainer = styled.div`
  img {
    height: 40px;
  }
`

const SocialsContainer = styled.div`
  min-width: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    justify-content: flex-start;
    text-align: left;
  }
`

const SocialsHeader = styled(Typography)`
  && {
    width: 100%;
    color: ${theme.colors.primary};
    margin-bottom: 30px;
  }
`

const Divider = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 60px;
  margin-bottom: 40px;
`

const Phone = styled(Typography)`
  && {
    text-align: center;
    margin: 10px 0;
    text-transform: none;

    a {
      color: #fff;
    }
  }
`

const Credits = styled(Typography)`
  && {
    margin-top: 40px;
    text-align: center;
  }
`

const useOptionsQuery = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          themeOptions {
            siteOptions {
              variant
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: AUTO
                      layout: FULL_WIDTH
                      quality: 90
                      placeholder: BLURRED
                    )
                  }
                }
              }
              headline
              text
              button {
                title
                url
                target
              }
              facebook
              instagram
              phone
              phoneTollFree
            }
          }
        }
      }
    `
  )
  return wp
}
