import React from "react"
import styled from 'styled-components'
import { Typography } from "@material-ui/core"

// import app components
import { Edges, BackgroundImage } from "components"
import * as theme from "theme"

const Hero = props => {
  const { headline, image } = props

  return (
    <Container>
      {image && <BackgroundImage image={image} />}

      <Edges size="md">
        <Content>
          {headline && (
            <Typography variant="h1" children={headline} color="inherit" />
          )}
        </Content>
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 60px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    height: calc(75vh - 80px);
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.3);
  }
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  color: #fff;
  text-align: center;
`

export default Hero
