import React from "react"
import styled from 'styled-components'
import { Typography } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import Spacer from "components/spacer"
import Edges from "components/edges"
import * as theme from "theme"
import { GatsbyImage } from "gatsby-plugin-image";

export default function Logos(props) {
  const { logos } = props

  return (
    <Container>
      <Edges size="md">
        <Spacer pt={{ xs: 20 }} pb={{ xs: 50 }}>
          <LogoContainer>
            {logos &&
              logos.map((o, i) => {
                return (
                  <ImageContainer
                    key={i}
                    href={o.link}
                    target="_blank"
                    rel="noopener"
                  >
                    {o?.image?.localFile?.childImageSharp?.gatsbyImageData && (
                      <GatsbyImage
                        image={o?.localFile?.childImageSharp?.gatsbyImageData}
                        alt={o.image?.altText ? o.image.altText : ""}
                      />
                    )}
                  </ImageContainer>
                )
              })}
          </LogoContainer>
        </Spacer>
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  background: #fff;
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const ImageContainer = styled.a`
  margin: 0 15px;

  .gatsby-image-wrapper {
    max-width: 150px;

    @media (min-width: 768px) {
      max-width: 220px;
    }

    img {
      height: auto !important;
      top: 50% !important;
      transform: translateY(-50%);
    }
  }
`
